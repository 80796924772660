<template>
  <div v-if="details">
    <h1 v-if="details.header" style="font-size: 32px">{{$i18n.locale === 'ru' ? details.header : details.header_en || details.header}}</h1>
    <div v-if="$i18n.locale === 'en' && details.content_en" id="news-item-text" class="news-item-text mt-2" v-html="details.content_en">
    </div>
    <div v-else id="news-item-text" class="news-item-text mt-2" v-html="details.content">
    </div>

    <b-col v-if="next" cols="12" lg="4" md="12" sm="12" class="mt-2 w-100">
      <q-btn small-padding :to="'/knowledge/'+ next" variant="primary" block> {{$t('knowledge.btn-next')}}</q-btn>
    </b-col>
  </div>
</template>

<script>

export default {
    metaInfo() {
      if (this.details) {
        let title = this.$i18n.locale === 'ru' ? this.details.header : this.details.header_en || this.details.header;
        title += ' | Qubity';
        const content = this.details.page_description;
        return {
          title: title,
          titleTemplate: null,
          meta: [
            {
              vmid: 'description',
              name: 'description',
              content: content
            }
          ],
          next: null
        }
      }
    },
    data() {
        return {
            details: null
        }
    },
   watch: {
    "$route.params.slug": function () {
      this.$request.get("knowledge.get", {
        slug: this.$route.params.slug,
        locale: localStorage.getItem('current_locale') || 'ru'
      }).then(rsp => {
        this.details = rsp.item;
        this.next = rsp.next.slug;
      }).catch(() => {
        this.$router.replace("/404")
      })
    }
  },
  beforeCreate() {
    this.$request.get("knowledge.get", {
      slug: this.$route.params.slug,
      locale: localStorage.getItem('current_locale') || 'ru',
    }).then(rsp => {
      this.details = rsp.item;
      this.next = rsp.next.slug;
    }).catch(() => {
      this.$router.replace("/404")
    })
  }
}
</script>